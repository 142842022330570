export const SUPPORT_LINKS = {
  WHY_SSN: "https://support.clipboardhealth.com/hc/en-us/articles/4550144249367",
  HELP_CENTER_WORKER: "https://workers.clipboardhealth.com/",
  BACKGROUND_CHECKS: "https://support.clipboardhealth.com/hc/en-us/articles/4535441657239",
  TIMESHEET_SIGNATURES: "https://support.clipboardhealth.com/hc/en-us/articles/4663665483031",
  ATTENDANCE_SCORE: "https://support.clipboardhealth.com/hc/en-us/articles/8230048132119",
  ACCOUNT_SUSP_DEACT: "https://support.clipboardhealth.com/hc/en-us/articles/8230048132119",
  DNR_REQUESTS_WORKER: "https://support.clipboardhealth.com/hc/en-us/articles/7904947056407",
  MULTI_STATE_LICENSES: "https://support.clipboardhealth.com/hc/en-us/articles/9978473726231",
  WORK_WITH_FRIENDS: "https://support.clipboardhealth.com/hc/en-us/articles/10411040507031",
  RATE_NEGOTIATION_WORKER: "https://support.clipboardhealth.com/hc/en-us/articles/11915644531351",
  TIME_NEGOTIATION_WORKER: "https://support.clipboardhealth.com/hc/en-us/articles/14972809329175",
  REFERRAL_BONUS: "https://support.clipboardhealth.com/hc/en-us/articles/6514925635991",
  ACCOUNT_STATUS: "https://support.clipboardhealth.com/hc/en-us/articles/12937542998295",
};
